import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import ContactForm from '../components/contact/Contact'
import Content from '../components/contact/Content'

const ContactPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Dedicated support team to help you get started with Atlas. Get in touch with us to learn more about Atlas. We are here to help.',
        title: 'Contact us',
        type: 'website'
      }}
      title={'Contact us'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <Content />
        </Fade>
      </main>
    </Layout>
  )
}

export default ContactPage
