import React, { useState } from 'react'
import styles from './Contact.module.scss'
import Form from '../common/Form'

const ContactForm = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.heading}>
          <h1>Fill form to talk</h1>
          <p>
            Please provide us with information about your company and we’ll get
            in touch shortly.
          </p>
        </div>
        <Form
          submitText={'Get in touch'}
          formName={'Contact'}
          successMessage={
            'We look forward to learning more about your organization and your goals. You will be contacted shortly to schedule a convenient time.'
          }
        />
      </div>
    </div>
  )
}

export default ContactForm
