import React from 'react'
import styles from './Content.module.scss'
import ContactForm from './Contact'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/solid'

const Content = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <ContactForm />
        <div className={styles.rightSection}>
          <div>
            <h3>What can you expect?</h3>
            <ul>
              <li className={styles.listItem}>
                <CheckIcon width={26} /> Discuss your use case and individual
                needs with our dedicated team
              </li>
              <li className={styles.listItem}>
                <CheckIcon width={26} /> Discover how our product can strengthen
                your spatial analysis and increase revenue
              </li>
              <li className={styles.listItem}>
                <CheckIcon width={26} /> Find out how others in your industry
                are leveraging Atlas
              </li>
            </ul>
            <p className={styles.subline}>
              <CheckCircleIcon width={26} /> Our team is ready to help you!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
